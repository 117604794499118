import styled from "styled-components";

export const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CardTitle = styled.div`
  line-height: 1.55;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.4rem;
`;

export const CardLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 0.625rem;
  height: 100%;
`;

export const TimeWrapper = styled.div`
  font-size: 0.5625rem;
  height: 1rem;
  line-height: calc(0.875rem);
  text-decoration: none;
  padding: 0px calc(0.416667rem) 0px calc(0.291667rem);
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  text-transform: uppercase;
  border-radius: 2rem;
  font-weight: 700;
  letter-spacing: 0.015625rem;
  cursor: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: transparent;
  color: rgb(73, 80, 87);
  border: 0.0625rem solid rgb(222, 226, 230);

  &::before {
    content: "";
    display: block;
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 0.25rem;
    background-color: rgb(8, 127, 91);
    margin-right: 0.25rem;
  }
`;

export const InfoSection = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 0rem;
`;

export const InfoValue = styled.div`
  span {
    font-size: 0.8rem;
    line-height: 1.55;
    text-decoration: none;
    color: rgb(134, 142, 150);
  }
`;

export const InfoTitle = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0px 0px 0rem;
  text-align: left;
`;
