export const ROLES = [
  {
    label: "ADMIN",
    value: "ADMIN",
  },
  {
    label: "USER",
    value: "USER",
  },
];
