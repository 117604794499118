import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Button, ConfigProvider, Layout, theme } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import { SideNav } from "./SideNav";

import {
  contentStyle,
  ContentWrapper,
  CustomContent,
  CustomHeader,
  HeaderLayout,
  LogoLayout,
  LogoText,
} from "./styles";

const { defaultAlgorithm } = theme;

export const Main = () => {
  const [navOpen, setNavOpen] = useState(false);
  const handleSidebarOpen = () => {
    setNavOpen(!navOpen);
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: defaultAlgorithm,
      }}
    >
      <Layout>
        <CustomHeader>
          <HeaderLayout>
            <Button
              icon={<MenuOutlined />}
              type="text"
              onClick={() => handleSidebarOpen()}
            />
            <LogoLayout>
              <img src="/img/logo.svg" width="24" height="24" alt="" />
              <LogoText>MPower.io</LogoText>
            </LogoLayout>
          </HeaderLayout>
        </CustomHeader>
        <Layout>
          <SideNav open={navOpen} handleOpen={handleSidebarOpen} />
          <CustomContent style={contentStyle}>
            <ContentWrapper>
              <Outlet />
            </ContentWrapper>
          </CustomContent>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};
