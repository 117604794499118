import { useEffect } from "react";
import { Button, Form, Input, Modal, Select } from "antd";

import { ROLES } from "./data";

import { AddUserFormWrapper } from "./styles";

export const AddUserModal = ({ initialData, isOpen, onClose, onSave }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onSave(values);
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData);
    }
  }, [initialData]);

  return (
    <Modal
      footer={[
        <Button key="save" onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
      forceRender
      onCancel={onClose}
      open={isOpen}
      title="Add user"
    >
      <AddUserFormWrapper>
        <Form
          autoComplete="off"
          form={form}
          initialValues={{
            name: "",
            email: "",
          }}
          layout="vertical"
          onFinish={onFinish}
          requiredMark={true}
        >
          <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                required: true,
                message: "Role is required!",
              },
            ]}
          >
            <Select
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={ROLES}
            ></Select>
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Name is required!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required!",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: initialData ? false : true,
                message: "Password is required!",
              },
            ]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item className="d-none">
            <Button htmlType="submit" type="primary"></Button>
          </Form.Item>
        </Form>
      </AddUserFormWrapper>
    </Modal>
  );
};
