import { Card } from "antd";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  &.ant-card {
    margin-bottom: 16px;
    border: none;

    .ant-card-head-title {
      text-transform: uppercase;
    }

    .ant-card-extra {
      .ant-radio-group:nth-child(2) {
        margin-left: 16px;
      }
    }

    @media (max-width: 768px) {
      .ant-card-extra {
        .ant-radio-group {
          display: block;
        }

        .ant-radio-group:nth-child(2) {
          margin-left: 0;
          margin-top: 4px;
        }
      }

      > .ant-card-body {
        padding: 24px 0;
      }
    }

    &.center {
      .ant-card-head-title {
        text-align: center;
      }
    }
  }
`;
