import styled from 'styled-components';

export const UsersWrapper = styled.div`
  width: 100%;
`;

export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;

  @media screen and (max-width: 660px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const TopActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 4px;
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const SubscribersActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

export const NotificationModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const AddUserFormWrapper = styled.div`
  .PhoneInput {
    input {
      background: #141414;
      color: white;
      height: 32px;
      border-radius: 6px;
      border: 1px solid #424242;
    }

    .flag-dropdown {
      background: #141414;
      border-color: #424242;

      .country-list {
        background: #141414;

        li {
          background: #141414;
          &:hover {
            background: #424242;
          }
        }
      }

      .selected-flag {
        background: #141414;
        &:hover {
          background: #141414;
        }
      }
    }
  }
`;
