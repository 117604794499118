import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, Card, Table } from "antd";
import { FieldTimeOutlined, LeftSquareOutlined } from "@ant-design/icons";

import ApiService from "../../services/ApiService";
import { DataSetsArray } from "../Datasets/data";
import { API_ROUTES } from "./data";

import {
  BackToButton,
  CardInfo,
  CardInfoBoldText,
  CardInfoText,
  CardInfoValueText,
  TableWrapper,
} from "./styles";

const { Title } = Typography;

export const DatasetDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const targetDataSet = DataSetsArray.find(
    (item) => item.id === params.datasetId
  );
  const ApiRoute = API_ROUTES.find((item) => item.id === params.datasetId);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);

    try {
      const { data } = await ApiService.get(ApiRoute.apiRoute);
      setTableData(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/datasets");
  };

  return (
    <div>
      <BackToButton onClick={handleBack}>
        <LeftSquareOutlined style={{ fontSize: 20, marginRight: 8 }} />
        Back to All Datasets
      </BackToButton>
      <Card title={ApiRoute.title} style={{ marginBottom: 12 }}>
        <CardInfo>
          <FieldTimeOutlined />
          <CardInfoText>Last Checked 1 minutes ago</CardInfoText>
        </CardInfo>
        <CardInfo>
          <CardInfoBoldText>Dataset ID: </CardInfoBoldText>
          <CardInfoValueText>{ApiRoute.id}</CardInfoValueText>
        </CardInfo>
        <CardInfo>
          <CardInfoBoldText>Date Range: </CardInfoBoldText>
          <CardInfoValueText>
            2023-06-19 EDT to 2023-12-20 EST
          </CardInfoValueText>
        </CardInfo>
        <CardInfo>
          <CardInfoBoldText>Row Count: </CardInfoBoldText>
          <CardInfoValueText>{tableData.length}</CardInfoValueText>
        </CardInfo>
      </Card>
      <Card title="Description">
        <Title level={5} style={{ margin: 0 }}>
          {targetDataSet ? targetDataSet.desc : ""}
        </Title>
      </Card>
      <Title level={4}>Data Preview</Title>
      <TableWrapper>
        <Table
          bordered
          columns={ApiRoute.tableColumns}
          dataSource={tableData}
          loading={loading}
          pageSize={20}
          pagination={{
            position: ["topLeft", "bottomRight"],
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          rowKey="id"
        />
      </TableWrapper>
    </div>
  );
};
