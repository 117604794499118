export const DataSetsArray = [
  {
    color: "#ee9944",
    desc: "Real Time System Conditions as reported by ERCOT. Updates ~10 seconds. Parses table available at: https://www.ercot.com/content/cdr/html/real_time_system_conditions.html",
    id: "ercot_real_time_system_conditions",
    lastCheckedAt: 1,
    title: "ERCOT Real Time System Conditions",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Real Time AS Monitor as reported by ERCOT. Includes capacity and responsiblity. Updates ~10 seconds. Parses table available at: https://www.ercot.com/content/cdr/html/as_capacity_monitor.html",
    id: "ercot_real_time_as_monitor",
    lastCheckedAt: 1,
    title: "ERCOT Real Time As Monitor",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "The Locational Marginal Price for each Electrical Bus in ERCOT, normally produced by SCED every five minutes. See list of available locations by using the ercot_electrical_buses dataset",
    id: "ercot_lmp_by_bus",
    lastCheckedAt: 1,
    title: "ERCOT LMP By Bus",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "The Locational Marginal Price for each Settlement Point in ERCOT, normally produced by SCED every five minutes.",
    id: "ercot_lmp_by_settlement_point",
    lastCheckedAt: 1,
    title: "ERCOT LMP By Settlement Point",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "SPP in hourly intervals as reported by ERCOT.",
    id: "ercot_spp_day_ahead_hourly",
    lastCheckedAt: 1,
    title: "ERCOT SPP Day Ahead Hourly",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "SPP in 15 minute intervals as reported by ERCOT.",
    id: "ercot_spp_real_time_15_min",
    lastCheckedAt: 1,
    title: "ERCOT SPP Real Time 15 Min",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Hourly wind report with forecasts as published by ERCOT. This report is posted every hour and includes System-wide and Regional actual hourly averaged wind power production, STWPF, WGRPP and COP HSLs for On-Line WGRs for a rolling historical 48-hour period as well as the System-wide and Regional STWPF, WGRPP and COP HSLs for On-Line WGRs for the rolling future 168-hour period. Our forecasts attempt to predict HSL, which is uncurtailed power generation potential.",
    id: "ercot_hourly_wind_report",
    lastCheckedAt: 1,
    title: "ERCOT Hourly Wind Report",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "System Lambda as reported by ERCOT.",
    id: "ercot_sced_system_lamda",
    lastCheckedAt: 1,
    title: "ERCOT Sced System Lambda",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Ancillary Services Reports in ERCOT with Total Cleared, Total Self-Arranged, and Bid Curves Published with a 2 day delay around 3am central",
    id: "ercot_as_reports",
    lastCheckedAt: 1,
    title: "ERCOT As Reports",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Hourly solar report with forecasts as published by ERCOT. Posted every hour and includes System-wide and geographic regional hourly averaged solar power production, STPPF, PVGRPP, and COP HSL for On-Line PVGRs for a rolling historical 48-hour period as well as the system-wide and regional STPPF, PVGRPP, and COP HSL for On-Line PVGRs for the rolling future 168-hour period.",
    id: "ercot_hourly_solar_report",
    lastCheckedAt: 1,
    title: "ERCOT Hourly Solar Report",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Get the price and the name of the Entity submitting the offer for the highest-priced Ancillary Service (AS) Offer. Published with 3 day delay",
    id: "ercot_highest_price_as_offer_selected",
    lastCheckedAt: 1,
    title: "ERCOT Highest Price As Offer Selected",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Hourly load by forecast zone as reported by ERCOT. Included zones: North, South, West, Houston, and ERCOT-wide total.",
    id: "ercot_load_by_forecast_zone",
    lastCheckedAt: 1,
    title: "ERCOT Load By Forecast Zone",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Hourly load by weather zone as reported by ERCOT. Included zones: Coast, East, Far West, North, North Central, South, South Central, West, and ERCOT-wide total.",
    id: "ercot_load_by_weather_zone",
    lastCheckedAt: 1,
    title: "ERCOT Load By Weather Zone",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Energy Storage Resources as reported by ERCOT. Includes Total Charging, Total Discharging, and Net Output",
    id: "ercot_ernergy_storage_resources",
    lastCheckedAt: 1,
    title: "ERCOT Energy Storage Resources",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Fuel Mix for ERCOT resampled to in 5 minute intervals.",
    id: "ercot_fuel_mix",
    lastCheckedAt: 1,
    title: "ERCOT Fuel Mix",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Fuel Mix as reported by ERCOT.",
    id: "ercot_fuel_mix_instant",
    lastCheckedAt: 1,
    title: "ERCOT Fuel Mix Instantaneous",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Load as reported by ERCOT.",
    id: "ercot_load",
    lastCheckedAt: 1,
    title: "ERCOT Load",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Denormalized hourly data for up to 5 years",
    id: "ercot_hourly_standardized_data",
    lastCheckedAt: 1,
    title: "ERCOT hourly standardized data",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Denormalized 5 minute data for up to 5 years",
    id: "ercot_5_minute_standardized_data",
    lastCheckedAt: 1,
    title: "ERCOT 5 minute standardized data",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Each hourly resource outage capacity reports published by ERCOT. For single time series of historical actuals and latest outlook, see `hourly_resource_outage_capacity`.",
    id: "ercot_hourly_resource_outage_capacity_reports",
    lastCheckedAt: 1,
    title: "ERCOT Hourly Resource Outage Capacity Reports",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Load Forecast reported by ERCOT resampled to 5 minute intervals.",
    id: "ercot_load_forecast",
    lastCheckedAt: 1,
    title: "ERCOT Load Forecast",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Forecasted hourly demands by Weather Zone and system total as reported by ERCOT. Released every hour for for the current day and six days forward.",
    id: "ercot_load_forecast_by_weather_zone",
    lastCheckedAt: 1,
    title: "ERCOT Load Forecast By Weather Zone",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Forecasted hourly demand by Forecast Zone and system total as reported by ERCOT. Released every hour for for the current day and six days forward.",
    id: "ercot_load_forecast_by_weather_zone",
    lastCheckedAt: 1,
    title: "ERCOT Load Forecast By Forecast Zone",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Hourly AS prices as reported by ERCOT.",
    id: "ercot_as_prices",
    lastCheckedAt: 1,
    title: "ERCOT As Prices",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Timeline of new records for ERCOT",
    id: "ercot_new_records_timeline",
    lastCheckedAt: 1,
    title: "ERCOT New Records Timeline",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Top 10 daily records for various metrics in ERCOT",
    id: "ercot_records",
    lastCheckedAt: 1,
    title: "ERCOT Records",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "SCED SMNE data. Published with 60 day delay.",
    id: "ercot_sced_smne_60_day",
    lastCheckedAt: 1,
    title: "ERCOT SCED SMNE 60 Day",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "SCED generation resource data. Published with 60 day delay. Contains offer curves, telemetry, and other data.",
    id: "ercot_sced_generation_resourece_60_day",
    lastCheckedAt: 1,
    title: "ERCOT SCED Generation Resource 60 Day",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "SCED load resource data. Published with 60 day delay. Contains bid curves, telemetry, and other data.",
    id: "ercot_sced_load_resource_60_day",
    lastCheckedAt: 1,
    title: "ERCOT SCED Load Resource 60 Day",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "DAM generation resource data. Published with 60 day delay.",
    id: "ercot_dam_generation_resource_60_day",
    lastCheckedAt: 1,
    title: "ERCOT DAM Generation Resource 60 Day",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "DAM load resource data. Published with 60 day delay.",
    id: "ercot_dam_load_resource_60_day",
    lastCheckedAt: 1,
    title: "ERCOT DAM Load Resource 60 Day",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "ERCOT SPP Real Time Price Corrections",
    id: "ercot_spp_real_time_price_corrections",
    lastCheckedAt: 1,
    title: "ERCOT SPP Real Time Price Corrections",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "ERCOT SPP Day Ahead Price Corrections",
    id: "ercot_spp_day_ahead_price_corrections",
    lastCheckedAt: 1,
    title: "ERCOT SPP Day Ahead Price Corrections",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "Historical actuals and latest outlook for resource outage capacity in ERCOT.",
    id: "ercot_hourly_resource_outage_capactiy",
    lastCheckedAt: 1,
    title: "Hourly Resource Outage Capacity",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "List of ERCOT electrical buses.",
    id: "ercot_electrical_buses",
    lastCheckedAt: 1,
    title: "ERCOT Electrical Buses",
    type: "ercot",
  },
  {
    color: "#ee9944",
    desc: "List of ERCOT generation resources.",
    id: "ercot_generation_resources",
    lastCheckedAt: 1,
    title: "ERCOT Generation Resources",
    type: "ercot",
  },
  {
    color: "#44dd88",
    desc: "VER curtailment data in 5 minute intervals for SPP. Total VER curtailment (not per resource). Data description: https://marketplace.spp.org/pages/ver-curtailments",
    id: "spp_ver_curtailments",
    lastCheckedAt: 1,
    title: "SPP Ver Curtailments",
    type: "spp",
  },
  {
    color: "#44dd88",
    desc: "Real Time 5 Minute LMPs for WEIS market as reported by SPP.",
    id: "spp_lmp_real_time_weis",
    lastCheckedAt: 1,
    title: "SPP LMP Real Time Weis",
    type: "spp",
  },
  {
    color: "#44dd88",
    desc: "Real Time 5 Minute LMPs as reported by SPP.",
    id: "spp_real_time_lmp_by_location",
    lastCheckedAt: 1,
    title: "SPP LMP Real Time 5 Min",
    type: "spp",
  },
  {
    color: "#44dd88",
    desc: "Day Ahead Hourly LMPs as reported by SPP.",
    id: "spp_da_lmp_by_location",
    lastCheckedAt: 1,
    title: "SPP LMP Day Ahead Hourly",
    type: "spp",
  },
  {
    color: "#44dd88",
    desc: "Generation capacity on outage data by fuel type in hourly intervals for next 7 days. Published each morning around 8am CT. Published daily around 8am CT. Data description: https://portal.spp.org/pages/capacity-of-generation-on-outage",
    id: "spp_generation_capacity_on_outage",
    lastCheckedAt: 1,
    title: "SPP Generation Capacity On Outage",
    type: "spp",
  },
  {
    color: "#44dd88",
    desc: "Fuel Mix in 5 minute intervals as reported by SPP.",
    id: "spp_fuel_mix",
    lastCheckedAt: 1,
    title: "SPP Fuel Mix",
    type: "spp",
  },
  {
    color: "#44dd88",
    desc: "Fuel Mix with self scheduled vs market breakdown in 5 minute intervals as reported by SPP.",
    id: "spp_fuel_mix_detailed",
    lastCheckedAt: 1,
    title: "SPP Fuel Mix Detailed",
    type: "spp",
  },
  {
    color: "#44dd88",
    desc: "Load in 5 minute intervals as reported by SPP.",
    id: "spp_load",
    lastCheckedAt: 1,
    title: "SPP Load",
    type: "spp",
  },
  {
    color: "#44dd88",
    desc: "",
    id: "spp_load_forecast",
    lastCheckedAt: 1,
    title: "SPP Load Forecast",
    type: "spp",
  },
  {
    color: "#99dd55",
    desc: "Denormalized hourly data for up to 5 years",
    id: "pjm_hourly_standardized_data",
    lastCheckedAt: 1,
    title: "PJM hourly standardized data",
    type: "pjm",
  },
  {
    color: "#99dd55",
    desc: "Denormalized 5 minute data for up to 5 years",
    id: "pjm_5_minute_standardized_data",
    lastCheckedAt: 1,
    title: "PJM 5 minute standardized data",
    type: "pjm",
  },
  {
    color: "#99dd55",
    desc: "",
    id: "pjm_lmp_day_ahead_hourly",
    lastCheckedAt: 1,
    title: "PJM LMP Day Ahead Hourly",
    type: "pjm",
  },
  {
    color: "#99dd55",
    desc: "",
    id: "pjm_lmp_real_time_5_min",
    lastCheckedAt: 1,
    title: "PJM LMP Real Time 5 Min",
    type: "pjm",
  },
  {
    color: "#99dd55",
    desc: "",
    id: "pjm_load_forecast",
    lastCheckedAt: 1,
    title: "PJM Load Forecast",
    type: "pjm",
  },
  {
    color: "#99dd55",
    desc: "",
    id: "pjm_load",
    lastCheckedAt: 1,
    title: "PJM Load",
    type: "pjm",
  },
  {
    color: "#99dd55",
    desc: "Fuel mix in hourly intervals as reported by PJM.",
    id: "pjm_fuel_mix_hourly",
    lastCheckedAt: 1,
    title: "PJM Fuel Mix Hourly",
    type: "pjm",
  },
  {
    color: "#99dd55",
    desc: "PJM fuel mix in 5 minute intervals. Based on hourly fuel mix data that is resampled and interpolated to 5 minute intervals.",
    id: "pjm_fuel_mix",
    lastCheckedAt: 1,
    title: "PJM Fuel Mix",
    type: "pjm",
  },
  {
    color: "#99dd55",
    desc: "Timeline of new records for PJM.",
    id: "pjm_new_records_timeline",
    lastCheckedAt: 1,
    title: "PJM New Records Timeline",
    type: "pjm",
  },
  {
    color: "#99dd55",
    desc: "Top 10 daily records for various metrics in PJM",
    id: "pjm_records",
    lastCheckedAt: 1,
    title: "PJM Records",
    type: "pjm",
  },
];
