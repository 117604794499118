import styled from "styled-components";

export const CardTitle = styled.div`
  line-height: 1.55;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2rem;
`;

export const CardLayout = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  -webkit-box-pack: start;
  justify-content: flex-start;
  gap: 0.625rem;
  height: 100%;
  width: 100%;
`;

export const CardInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 10px 0px;
  gap: 4px;
`;

export const CardInfoText = styled.div`
  color: #868e96;
  font-size: 14px;
`;

export const InfoBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  font-size: 0.6875rem;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  padding: 4px 8px;
  background-color: ${(props) => `${props.color}`};
`;

export const CardDesc = styled.div`
  flex-grow: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-word;
`;

export const CardActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: 1px solid #087f5b;
  border-radius: 6px;
  width: fit-content;
  padding: 8px 12px;
  background-color: white;
  color: #087f5b;
  margin-top: 8px;
  font-size: 16px;
  cursor: pointer;
`;
