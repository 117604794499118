import { useEffect } from "react";
import { Navigate, Routes, Route } from "react-router-dom";

import { Auth } from "./layouts/Auth/Auth";
import { Main } from "./layouts/Main";
import { Datasets } from "./pages/Datasets";
import { DatasetDetails } from "./pages/DatasetDetails";
import { Home } from "./pages/Home";
import { Caiso } from "./pages/Live/Caiso";
import { Ercot } from "./pages/Live/Ercot";
import { Login } from "./pages/Auth/Login";
import { Miso } from "./pages/Live/Miso";
import { Pjm } from "./pages/Live/Pjm";
import { Spp } from "./pages/Live/Spp";
import { Users } from "./pages/Users/Users";

import SessionService from "./services/SessionService";

let timeoutId = null;

function App() {
  const isLoggedIn = SessionService.isLoggedIn();

  useEffect(() => {
    if (isLoggedIn && !timeoutId) {
      const expiresIn = SessionService.getExpiresIn();

      timeoutId = setTimeout(() => {
        SessionService.logout();
      }, expiresIn);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return (
      <Routes>
        <Route path="/auth" element={<Auth />}>
          <Route path="login" element={<Login />} />
          <Route index element={<Navigate to="login" replace />} />
          <Route path="*" element={<Navigate to="login" replace />} />
        </Route>
        <Route path="*" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route path="home" element={<Home />} />
        <Route path="datasets" element={<Datasets />} />
        <Route path="datasets/:datasetId" element={<DatasetDetails />} />
        <Route index element={<Navigate to="home" replace />} />
        <Route path="live/caiso" element={<Caiso />} />
        <Route path="live/ercot" element={<Ercot />} />
        <Route path="live/miso" element={<Miso />} />
        <Route path="live/pjm" element={<Pjm />} />
        <Route path="live/spp" element={<Spp />} />
        <Route path="users" element={<Users />} />
        <Route path="*" element={<Navigate to="home" replace />} />
      </Route>
    </Routes>
  );
}

export default App;
