export const API_ROUTES = [
  {
    title: "ERCOT Real Time System Conditions",
    id: "ercot_real_time_system_conditions",
    apiRoute: "/data/ercot/real-time-system-conditions",
    tableColumns: [
      {
        id: "date",
        title: "time_utc",
        render: (record) => <div>{record.date}</div>,
      },
      {
        id: "current_frequency",
        title: "current_frequency",
        render: (record) => <div>{record.currencyFrequency}</div>,
      },
      {
        id: "frequency_instantaneous_time_error",
        title: "frequency_instantaneous_time_error",
        render: (record) => <div>{record.frequencyInstantaneousTimeError}</div>,
      },
      {
        id: "frequency_consecutive_baal_clock_minute_exceedances_(min)",
        title: "frequency_consecutive_baal_clock_minute_exceedances_(min)",
        render: (record) => (
          <div>{record.frequencyConsecutiveBaalClockMinuteExceedances}</div>
        ),
      },
      {
        id: "actual_system_demand",
        title: "actual_system_demand",
        render: (record) => <div>{record.actualSystemDemand}</div>,
      },
      {
        id: "average_net_load",
        title: "average_net_load",
        render: (record) => <div>{record.averageNetLoad}</div>,
      },
      {
        id: "total_system_capacity_excluding_ancillary_services",
        title: "total_system_capacity_excluding_ancillary_services",
        render: (record) => (
          <div>{record.totalSystemCapacityExcludingAncillaryServices}</div>
        ),
      },
      {
        id: "total_wind_output",
        title: "total_wind_output",
        render: (record) => <div>{record.totalWindOutput}</div>,
      },
      {
        id: "total_pvgr_output",
        title: "total_pvgr_output",
        render: (record) => <div>{record.totalPvgrOutput}</div>,
      },
      {
        id: "current_system_inertia",
        title: "current_system_inertia",
        render: (record) => <div>{record.currentSystemInertia}</div>,
      },
      {
        id: "dc_tie_flows_dc_e_(east)",
        title: "dc_tie_flows_dc_e_(east)",
        render: (record) => <div>{record.DCE}</div>,
      },
      {
        id: "dc_tie_flows_dc_l_(laredo_vft)",
        title: "dc_tie_flows_dc_l_(laredo_vft)",
        render: (record) => <div>{record.DCL}</div>,
      },
      {
        id: "dc_tie_flows_dc_n_(north)",
        title: "dc_tie_flows_dc_n_(north)",
        render: (record) => <div>{record.DCN}</div>,
      },
      {
        id: "dc_tie_flows_dc_r_(railroad)",
        title: "dc_tie_flows_dc_r_(railroad)",
        render: (record) => <div>{record.DCR}</div>,
      },
      {
        id: "dc_tie_flows_dc_s_(eagle_pass)",
        title: "dc_tie_flows_dc_s_(eagle_pass)",
        render: (record) => <div>{record.DCS}</div>,
      },
    ],
  },
  {
    title: "ERCOT LMP By Bus",
    id: "ercot_lmp_by_bus",
    apiRoute: "/data/ercot/lmp-by-bus",
    tableColumns: [
      {
        id: "date",
        title: "sced_timestamp",
        render: (record) => <div>{record.date}</div>,
      },
      {
        id: "market",
        title: "market",
        render: (record) => <div>REAL_TIME_SCED</div>,
      },
      {
        id: "location",
        title: "location",
        render: (record) => <div>{record.location}</div>,
      },
      {
        id: "location_type",
        title: "location_type",
        render: (record) => <div>Electrical Bus</div>,
      },
      {
        id: "lmp",
        title: "lmp",
        render: (record) => <div>{record.lmp}</div>,
      },
    ],
  },
  {
    title: "ERCOT Real Time As Monitor",
    id: "ercot_real_time_as_monitor",
    apiRoute: "/data/ercot/real-time-as-monitor",
    tableColumns: [
      {
        dataIndex: "date",
        title: "Date",
      },
      {
        title: "Generation Resources",
        dataIndex: "rrsCapacityGen",
      },
      {
        title: "Load Resources excluding Controllable Load Resources",
        dataIndex: "rrsCapacityExControllableLoad",
      },
      {
        title:
          "Unprocured additional capacity from Load Resources excluding Controllable Load Resources",
        dataIndex: "rrsCapacityUnprocuredAdditionalCapacityFromExControllabl",
      },
      {
        title: "Controllable Load Resources",
        dataIndex: "rrsCapacityControllableLoad",
      },
      {
        title: "Resources capable of Fast Frequency Response",
        dataIndex: "rrsCapacityResourcesCapableOfFfr",
      },
      {
        title: "Deployed Generation Resources and Controllable Load Resources",
        dataIndex: "rrsCapacityDeployedGenAndControllableLoad",
      },
      {
        title: "Generation Resources",
        dataIndex: "rrsResponsibilityGen",
      },
      {
        title: "Load Resources excluding Controllable Load Resources",
        dataIndex: "rrsResponsibilityExControllableLoad",
      },
      {
        title: "Resources capable of Fast Frequency Response",
        dataIndex: "rrsResponsibilityResourcesCapableOfFfr",
      },
      {
        title: "Controllable Load Resources",
        dataIndex: "rrsResponsibilityControllableLoad",
      },
      {
        title: "Generation Resources",
        dataIndex: "ecrsCapacityGen",
      },
      {
        title: "Load Resources excluding Controllable Load Resources",
        dataIndex: "ecrsCapacityExControllableLoad",
      },
      {
        title: "Controllable Load Resources",
        dataIndex: "ecrsCapacityControllableLoad",
      },
      {
        title: "Quick Start Generation Resources",
        dataIndex: "ecrsCapacityQuickStartGen",
      },
      {
        title: "Deployed Generation Resources and Load Resources",
        dataIndex: "ecrsCapacityDeployedGenAndLoad",
      },
      {
        title: "Generation Resources",
        dataIndex: "ecrsResponsibilityGen",
      },
      {
        title: "Load Resources excluding Controllable Load Resources",
        dataIndex: "ecrsResponsibilityExControllableLoad",
      },
      {
        title: "Load Controllable Load Resources",
        dataIndex: "ecrsResponsibilityControllableLoad",
      },
      {
        title: "Quick Start Generation Resources",
        dataIndex: "ecrsResponsibilityQuickStartGen",
      },
      {
        title:
          "On-Line Generation Resources With Energy Offer Curves including Quick Start Generation Resources",
        dataIndex: "nspinCapacityOnLineQsgrWithOfferCurves",
      },
      {
        title: "On-Line Generation Resources with Output Schedules",
        dataIndex: "nspinCapacityOnLineGenWithOutputSchedules",
      },
      {
        title: "Undeployed Load Resources",
        dataIndex: "nspinCapacityUndeployedLoad",
      },
      {
        title: "Off-Line Generation Resources",
        dataIndex: "nspinCapacityOffLineGen",
      },
      {
        title: "On-Line Generation Resources with Energy Offer Curves",
        dataIndex: "nspinResponsibilityOnLineGenWithEnergyOfferCurves",
      },
      {
        title: "On-Line Generation Resources with Output Schedules",
        dataIndex: "nspinResponsibilityOnLineGenWithOutputSchedules",
      },
      {
        title: "On-Load Resources",
        dataIndex: "nspinResponsibilityLoad",
      },
      {
        title:
          "Off-Line Generation Resources excluding Quick Start Generation Resources",
        dataIndex: "nspinResponsibilityOffLineGenExQuickStartGen",
      },
      {
        title: "Quick Start Generation Resources",
        dataIndex: "nspinResponsibilityQuickStartGen",
      },
      {
        title: "Undeployed Reg-Up",
        dataIndex: "regCapacityUndeployedRegUp",
      },
      {
        title: "Undeployed Reg-Down",
        dataIndex: "regCapacityUndeployedRegDown",
      },
      {
        title: "Deployed Reg-Up",
        dataIndex: "regCapacityDeployedRegUp",
      },
      {
        title: "Deployed Reg-Down",
        dataIndex: "regCapacityDeployedRegDown",
      },
      {
        title: "Reg-Up",
        dataIndex: "regResponsibilityRegUp",
      },
      {
        title: "Reg-Down",
        dataIndex: "regResponsibilityRegDown",
      },
      {
        title:
          "Capacity from Controllable Load Resources available to decrease Base Points (energy consumption) in SCED",
        dataIndex: "capacityFromClrsAvailableToDecreaseBasePointsInSced",
      },
      {
        title:
          "Capacity from Controllable Load Resources available to increase Base Points (energy consumption) in SCED",
        dataIndex: "capacityFromClrsAvailableToIncreaseBasePointsInSced",
      },
      {
        title:
          "Capacity with Energy Offer Curves available to increase Generation Resource Base Points in SCED",
        dataIndex: "capacityWithEnergyOfferCurvesToIncreaseGenresBpInSced",
      },
      {
        title:
          "Capacity with Energy Offer Curves available to decrease Generation Resource Base Points in SCED",
        dataIndex: "capacityWithEnergyOfferCurvesToDecreaseGenresBpInSced",
      },
      {
        title:
          "Capacity without Energy Offer Curves available to increase Generation Resource Base Points in SCED",
        dataIndex: "capacityWithoutEnergyOffersToIncreaseGenresBpInSced",
      },
      {
        title:
          "Capacity without Energy Offer Curves available to decrease Generation Resource Base Points in SCED",
        dataIndex: "capacityWithoutEnergyOffersToDecreaseGenresBpInSced",
      },
      {
        title:
          "Capacity available to increase Generation Resource Base Points in the next 5 minutes in SCED (HDL)",
        dataIndex: "capacityToIncreaseGenresBpInNextFiveMinutesInScedHdl",
      },
      {
        title:
          "Capacity available to decrease Generation Resource Base Points in the next 5 minutes in SCED (LDL)",
        dataIndex: "capacityToDecreaseGenresBpInNextFiveMinutesInScedLdl",
      },
      {
        title: "ERCOT-wide Physical Responsive Capability (PRC)",
        dataIndex: "prc",
      },
      {
        title: "Real-Time On-Line reserve capacity",
        dataIndex: "ordcOnline",
      },
      {
        title: "Real-Time On-Line and Off-Line reserve capacity",
        dataIndex: "ordcOnlineAndOffline",
      },
    ],
  },
  {
    title: "SPP Ver Curtailments",
    id: "spp_ver_curtailments",
    apiRoute: "/data/spp/ver-curtailments",
    tableColumns: [
      {
        id: "date",
        title: "interval_start_utc",
        render: (record) => <div>{record.date}</div>,
      },
      {
        id: "windManualCurtailments",
        title: "Wind ManualCurtailments",
        render: (record) => <div>{record.windManualCurtailments}</div>,
      },
      {
        id: "windRedispatchCurtailments",
        title: "Wind RedispatchCurtailments",
        render: (record) => <div>{record.windRedispatchCurtailments}</div>,
      },
    ],
  },
  {
    title: "SPP LMP Real Time Weis",
    id: "spp_lmp_real_time_weis",
    apiRoute: "/data/spp/lmp-by-location-weis",
    tableColumns: [
      {
        id: "date",
        title: "interval_start_utc",
        render: (record) => <div>{record.date}</div>,
      },
      {
        id: "location",
        title: "Location",
        render: (record) => <div>{record.location}</div>,
      },
      {
        id: "lmp",
        title: "Lmp",
        render: (record) => <div>{record.lmp}</div>,
      },
      {
        id: "mcc",
        title: "Mcc",
        render: (record) => <div>{record.mcc}</div>,
      },
      {
        id: "mec",
        title: "Mec",
        render: (record) => <div>{record.mec}</div>,
      },
      {
        id: "mlc",
        title: "Mlc",
        render: (record) => <div>{record.mlc}</div>,
      },
      {
        id: "pnode",
        title: "Pnode",
        render: (record) => <div>{record.pnode}</div>,
      },
    ],
  },
  {
    title: "SPP LMP Real Time 5 Min",
    id: "spp_real_time_lmp_by_location",
    apiRoute: "/data/spp/real-time-lmp-by-location",
    tableColumns: [
      {
        id: "date",
        title: "interval_start_utc",
        render: (record) => <div>{record.date}</div>,
      },
      {
        id: "location",
        title: "Location",
        render: (record) => <div>{record.location}</div>,
      },
      {
        id: "lmp",
        title: "Lmp",
        render: (record) => <div>{record.lmp}</div>,
      },
      {
        id: "mcc",
        title: "Mcc",
        render: (record) => <div>{record.mcc}</div>,
      },
      {
        id: "mec",
        title: "Mec",
        render: (record) => <div>{record.mec}</div>,
      },
      {
        id: "mlc",
        title: "Mlc",
        render: (record) => <div>{record.mlc}</div>,
      },
      {
        id: "pnode",
        title: "Pnode",
        render: (record) => <div>{record.pnode}</div>,
      },
    ],
  },
  {
    title: "SPP LMP Day Ahead Hourly",
    id: "spp_da_lmp_by_location",
    apiRoute: "/data/spp/da-lmp-by-location",
    tableColumns: [
      {
        id: "date",
        title: "interval_start_utc",
        render: (record) => <div>{record.date}</div>,
      },
      {
        id: "location",
        title: "Location",
        render: (record) => <div>{record.location}</div>,
      },
      {
        id: "lmp",
        title: "Lmp",
        render: (record) => <div>{record.lmp}</div>,
      },
      {
        id: "mcc",
        title: "Mcc",
        render: (record) => <div>{record.mcc}</div>,
      },
      {
        id: "mec",
        title: "Mec",
        render: (record) => <div>{record.mec}</div>,
      },
      {
        id: "mlc",
        title: "Mlc",
        render: (record) => <div>{record.mlc}</div>,
      },
      {
        id: "pnode",
        title: "Pnode",
        render: (record) => <div>{record.pnode}</div>,
      },
    ],
  },
  {
    title: "SPP Generation Capacity On Outage",
    id: "spp_generation_capacity_on_outage",
    apiRoute: "/data/spp/generation-capacity-on-outage",
    tableColumns: [
      {
        id: "date",
        title: "Date",
        render: (record) => <div>{record.date}</div>,
      },
      {
        id: "coalMW",
        title: "Coal MW",
        render: (record) => <div>{record.coalMW}</div>,
      },
      {
        id: "dieselFuelOilMW",
        title: "Diesel Fuel Oil MW",
        render: (record) => <div>{record.dieselFuelOilMW}</div>,
      },
      {
        id: "hydroMW",
        title: "Hydro MW",
        render: (record) => <div>{record.hydroMW}</div>,
      },
      {
        id: "naturalGasMW",
        title: "Natural Gas MW",
        render: (record) => <div>{record.naturalGasMW}</div>,
      },
      {
        id: "nuclearMW",
        title: "Nuclear MW",
        render: (record) => <div>{record.nuclearMW}</div>,
      },
      {
        id: "otherMW",
        title: "Other MW",
        render: (record) => <div>{record.otherMW}</div>,
      },
      {
        id: "outagedMW",
        title: "Outaged MW",
        render: (record) => <div>{record.outagedMW}</div>,
      },
      {
        id: "solarMW",
        title: "Solar MW",
        render: (record) => <div>{record.solarMW}</div>,
      },
      {
        id: "wasteDisposalMW",
        title: "Waste Disposal MW",
        render: (record) => <div>{record.wasteDisposalMW}</div>,
      },
      {
        id: "wasteHeatMW",
        title: "Waste Heat MW",
        render: (record) => <div>{record.wasteHeatMW}</div>,
      },
      {
        id: "windMW",
        title: "Wind MW",
        render: (record) => <div>{record.windMW}</div>,
      },
    ],
  },
  {
    title: "SPP Fuel Mix",
    id: "spp_fuel_mix",
    apiRoute: "/data/spp/fuel-mix",
    tableColumns: [
      {
        id: "date",
        title: "Date",
        render: (record) => <div>{record.date}</div>,
      },
      {
        id: "coal",
        title: "Coal",
        render: (record) => <div>{record.coal}</div>,
      },
      {
        id: "dieselFuelOil",
        title: "Diesel Fuel Oil",
        render: (record) => <div>{record.dieselFuelOil}</div>,
      },
      {
        id: "hydro",
        title: "Hydro",
        render: (record) => <div>{record.hydro}</div>,
      },
      {
        id: "naturalGas",
        title: "Natural Gas",
        render: (record) => <div>{record.naturalGas}</div>,
      },
      {
        id: "nuclear",
        title: "Nuclear",
        render: (record) => <div>{record.nuclear}</div>,
      },
      {
        id: "other",
        title: "Other",
        render: (record) => <div>{record.other}</div>,
      },
      {
        id: "solar",
        title: "Solar",
        render: (record) => <div>{record.solar}</div>,
      },
      {
        id: "wasteDisposalServices",
        title: "Waste Disposal Services",
        render: (record) => <div>{record.wasteDisposalServices}</div>,
      },
      {
        id: "wasteHeat",
        title: "Waste Heat",
        render: (record) => <div>{record.wasteHeat}</div>,
      },
      {
        id: "wind",
        title: "Wind",
        render: (record) => <div>{record.wind}</div>,
      },
    ],
  },
  {
    title: "SPP Fuel Mix Detailed",
    id: "spp_fuel_mix_detailed",
    apiRoute: "/data/spp/fuel-mix-detailed",
    tableColumns: [
      {
        id: "date",
        title: "Date",
        render: (record) => <div>{record.date}</div>,
      },
      {
        id: "coalMarket",
        title: "Coal Market",
        render: (record) => <div>{record.coalMarket}</div>,
      },
      {
        id: "coalSelf",
        title: "Coal Self",
        render: (record) => <div>{record.coalSelf}</div>,
      },
      {
        id: "dieselFuelOilMarket",
        title: "Diesel Fuel Oil Market",
        render: (record) => <div>{record.dieselFuelOilMarket}</div>,
      },
      {
        id: "dieselFuelOilSelf",
        title: "Diesel Fuel Oil Self",
        render: (record) => <div>{record.dieselFuelOilSelf}</div>,
      },
      {
        id: "hydroMarket",
        title: "Hydro Market",
        render: (record) => <div>{record.hydroMarket}</div>,
      },
      {
        id: "hydroSelf",
        title: "Hydro Self",
        render: (record) => <div>{record.hydroSelf}</div>,
      },
      {
        id: "naturalGasMarket",
        title: "Natural Gas Market",
        render: (record) => <div>{record.naturalGasMarket}</div>,
      },
      {
        id: "naturalGasSelf",
        title: "Natural Gas Self",
        render: (record) => <div>{record.naturalGasSelf}</div>,
      },
      {
        id: "nuclearMarket",
        title: "Nuclear Market",
        render: (record) => <div>{record.nuclearMarket}</div>,
      },
      {
        id: "nuclearSelf",
        title: "Nuclear Self",
        render: (record) => <div>{record.nuclearSelf}</div>,
      },
      {
        id: "otherMarket",
        title: "Other Market",
        render: (record) => <div>{record.otherMarket}</div>,
      },
      {
        id: "otherSelf",
        title: "Other Self",
        render: (record) => <div>{record.otherSelf}</div>,
      },
      {
        id: "solarMarket",
        title: "Solar Market",
        render: (record) => <div>{record.solarMarket}</div>,
      },
      {
        id: "solarSelf",
        title: "Solar Self",
        render: (record) => <div>{record.solarSelf}</div>,
      },
      {
        id: "wasteDisposalServicesMarket",
        title: "Waste Disposal Services Market",
        render: (record) => <div>{record.wasteDisposalServicesMarket}</div>,
      },
      {
        id: "wasteDisposalServicesSelf",
        title: "Waste Disposal Services Self",
        render: (record) => <div>{record.wasteDisposalServicesSelf}</div>,
      },
      {
        id: "wasteHeatMarket",
        title: "Waste Heat Market",
        render: (record) => <div>{record.wasteHeatMarket}</div>,
      },
      {
        id: "wasteHeatSelf",
        title: "Waste Heat Self",
        render: (record) => <div>{record.wasteHeatSelf}</div>,
      },
      {
        id: "windMarket",
        title: "Wind Market",
        render: (record) => <div>{record.windMarket}</div>,
      },
      {
        id: "windSelf",
        title: "Wind Self",
        render: (record) => <div>{record.windSelf}</div>,
      },
    ],
  },
  {
    title: "SPP Load",
    id: "spp_load",
    apiRoute: "/data/spp/load",
    tableColumns: [
      {
        id: "date",
        title: "Date",
        render: (record) => <div>{record.date}</div>,
      },
      {
        id: "load",
        title: "Load",
        render: (record) => <div>{record.load}</div>,
      },
    ],
  },
  {
    title: "SPP Load Forecast",
    id: "spp_load_forecast",
    apiRoute: "/data/spp/load-forecast",
    tableColumns: [
      {
        id: "date",
        title: "Date",
        render: (record) => <div>{record.date}</div>,
      },
      {
        id: "forecastedAt",
        title: "Forecast Time",
        render: (record) => <div>{record.forecastedAt}</div>,
      },
      {
        id: "load",
        title: "Load",
        render: (record) => <div>{record.load}</div>,
      },
    ],
  },
];
