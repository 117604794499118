import { Outlet } from "react-router-dom";
import { ConfigProvider, theme } from "antd";

import { Main } from "./styles";

const { defaultAlgorithm } = theme;

export const Auth = () => (
  <ConfigProvider
    theme={{
      algorithm: defaultAlgorithm,
    }}
  >
    <Main>
      <Outlet />
    </Main>
  </ConfigProvider>
);
