import { useNavigate } from "react-router-dom";
import { Card } from "antd";
import { ArrowRightOutlined, FieldTimeOutlined } from "@ant-design/icons";

import {
  CardActionButton,
  CardDesc,
  CardInfo,
  CardInfoText,
  CardLayout,
  CardTitle,
  InfoBadge,
} from "./styles";

export const DataSetCard = ({ cardInfo }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/datasets/${cardInfo.id}`);
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardLayout>
        <CardTitle>{cardInfo.title}</CardTitle>
        <CardInfo>
          <FieldTimeOutlined />
          <CardInfoText>
            Last Checked {cardInfo.lastCheckedAt} minutes ago
          </CardInfoText>
          <InfoBadge color={cardInfo.color}>{cardInfo.type}</InfoBadge>
        </CardInfo>
        <CardDesc>{cardInfo.desc}</CardDesc>
        <CardActionButton onClick={handleNavigate}>
          <div>See Details</div>
          <ArrowRightOutlined />
        </CardActionButton>
      </CardLayout>
    </Card>
  );
};
