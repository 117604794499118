import styled from "styled-components";

export const CardTitle = styled.div`
  line-height: 1.55;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.4rem;
`;

export const CardLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;

export const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
