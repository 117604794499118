import { useMemo, useState } from "react";
import { Col, Input, Row, Select, Switch, Typography } from "antd";

import { DataSetCard } from "../../components/DataSetCard";
import { DataSetsArray } from "./data";

import {
  DataSetsContainer,
  DataSetsHeader,
  DataSetsHeaderMatchingText,
  HeaderActions,
  SwitchWrapper,
} from "./styles";

const { Title } = Typography;

export const Datasets = () => {
  const [search, setSearch] = useState("");
  const [currentDataSet, setCurrentDataSet] = useState("");
  const [currentSort, setCurrentSort] = useState("");
  const [groupBy, setGroupBy] = useState(false);

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
  };
  const onDataSetChange = (value) => {
    setCurrentDataSet(value);
  };
  const onSortChange = (value) => {
    setCurrentSort(value);
  };
  const onGroupByChange = (checked) => {
    setGroupBy(checked);
  };
  const filteredDataSets = useMemo(() => {
    let dataSets = DataSetsArray;

    if (currentDataSet) {
      dataSets = DataSetsArray.filter(
        (dataSet) => dataSet.type === currentDataSet
      );
    }

    return dataSets;
  }, [currentDataSet]);

  return (
    <div>
      <Title level={3} style={{ margin: 0 }}>
        Dataset Research
      </Title>
      <HeaderActions>
        <Input
          onChange={onChangeSearch}
          placeholder="Filter datasets by name or description"
          style={{ maxWidth: 400 }}
          value={search}
        />
        <Select
          onChange={onDataSetChange}
          options={[
            { value: "", label: "All sources" },
            { value: "spp", label: "SPP" },
            { value: "pjm", label: "PJM" },
            { value: "ercot", label: "ERCOT" },
          ]}
          style={{ width: 120 }}
          value={currentDataSet}
        />
        <Select
          onChange={onSortChange}
          options={[
            { value: "", label: "Sort by" },
            { value: "name-asc", label: "Name (A-Z)" },
            { value: "name-desc", label: "Name (Z-A)" },
            { value: "date-earliest-asc", label: "Earliest Data (Asc)" },
            { value: "date-earliest-desc", label: "Earliest Data (Desc)" },
            { value: "date-latest-asc", label: "Latest Data (Asc)" },
            { value: "date-latest-desc", label: "Latest Data (Desc)" },
            { value: "last-checked-desc", label: "Last Checked (Desc)" },
          ]}
          style={{ minWidth: 200 }}
          value={currentSort}
        />
        <SwitchWrapper>
          <Switch onChange={onGroupByChange} checked={groupBy} />
          <Title level={5} style={{ margin: 0 }}>
            Group by Source
          </Title>
        </SwitchWrapper>
      </HeaderActions>
      <DataSetsContainer>
        <DataSetsHeader>
          <Title level={4} style={{ margin: 0, textTransform: "uppercase" }}>
            {currentDataSet}
          </Title>
          <DataSetsHeaderMatchingText>
            {filteredDataSets.length} matching datasets
          </DataSetsHeaderMatchingText>
        </DataSetsHeader>
        <Row gutter={[16, 16]}>
          {filteredDataSets.length > 0 &&
            filteredDataSets.map((card, index) => (
              <Col key={index} xs={24} sm={12} md={12} lg={8}>
                <DataSetCard cardInfo={card} />
              </Col>
            ))}
        </Row>
      </DataSetsContainer>
    </div>
  );
};
