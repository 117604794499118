import { Card } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { CardLayout, CardTitle, ChartWrapper } from "./styles";

export const ChartCard = ({ chartInfo }) => {
  return (
    <Card>
      <CardLayout>
        <CardTitle>{chartInfo.title}</CardTitle>
        <ChartWrapper>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartInfo.options}
          />
        </ChartWrapper>
      </CardLayout>
    </Card>
  );
};
