import { Link } from "react-router-dom";
import {
  AimOutlined,
  DatabaseOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";

export const MENU_ITEMS = [
  {
    key: "home",
    label: <Link to="/home">Home</Link>,
    icon: <HomeOutlined />,
  },
  {
    key: "live",
    label: <Link to="/live/caiso">Live</Link>,
    icon: <AimOutlined />,
    children: [
      {
        key: "live/caiso",
        label: <Link to="/live/caiso">CAISO</Link>,
      },
      {
        key: "live/ercot",
        label: <Link to="/live/ercot">ERCOT</Link>,
      },
      {
        key: "live/miso",
        label: <Link to="/live/miso">MISO</Link>,
      },
      {
        key: "live/pjm",
        label: <Link to="/live/pjm">PJM</Link>,
      },
      {
        key: "live/spp",
        label: <Link to="/live/spp">SPP</Link>,
      },
    ],
  },
  {
    key: "users",
    label: <Link to="/users">Users</Link>,
    icon: <UserOutlined />,
  },
  {
    key: "datasets",
    label: <Link to="/datasets">Datasets</Link>,
    icon: <DatabaseOutlined />,
  },
];
