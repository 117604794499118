import { Link, useLocation } from "react-router-dom";
import { Avatar, Button, Dropdown, Menu } from "antd";
import {
  CloseOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import { MENU_ITEMS } from "./data";
import SessionService from "../../services/SessionService";

import {
  DropdownWrapper,
  LogoLayout,
  LogoText,
  Sidebar,
  SidebarTopLayout,
  TopMenuEmail,
  TopMenuName,
  TopMenuTitles,
  TopMenuWrapper,
} from "./styles";

export const SideNav = ({ open, handleOpen }) => {
  const { pathname } = useLocation();
  const selectedKey = pathname.replace("/", "");
  const userData = SessionService.getUserData();

  let customItem = [
    {
      label: (
        <TopMenuWrapper>
          <Avatar
            size="large"
            style={{
              background: "#e6526d",
              marginRight: 15,
            }}
          >
            {userData.name[0]}
          </Avatar>
          <TopMenuTitles>
            <TopMenuName>{userData.name}</TopMenuName>
            <TopMenuEmail>{userData.email}</TopMenuEmail>
          </TopMenuTitles>
        </TopMenuWrapper>
      ),
      key: "0",
      disabled: true,
    },
    {
      key: "1",
      label: (
        <TopMenuWrapper>
          <SettingOutlined style={{ marginRight: 15 }} />
          Manage account
        </TopMenuWrapper>
      ),
    },
    {
      label: (
        <TopMenuWrapper>
          <LogoutOutlined style={{ marginRight: 15 }} />
          <Link
            to="/"
            onClick={(e) => {
              e.preventDefault();
              SessionService.logout();
            }}
            style={{ color: "black" }}
          >
            Log Out
          </Link>
        </TopMenuWrapper>
      ),
      key: "2",
    },
  ];

  return (
    <Sidebar open={open}>
      <SidebarTopLayout>
        <LogoLayout>
          <img src="/img/logo.png" width="24" height="24" alt="" />
          <LogoText>MPower.io</LogoText>
        </LogoLayout>
        <Button
          className="close-btn"
          icon={<CloseOutlined />}
          onClick={() => handleOpen()}
          type="text"
        />
      </SidebarTopLayout>
      <Menu items={MENU_ITEMS} mode="inline" selectedKeys={[selectedKey]} />
      <DropdownWrapper>
        <Dropdown
          menu={{
            items: customItem,
            style: {
              marginTop: 15,
            },
          }}
          placement="topLeft"
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()} style={{ color: "black" }}>
            <Avatar
              size="large"
              style={{
                background: "#e6526d",
                marginRight: 15,
              }}
            >
              {userData.name[0]}
            </Avatar>
            {userData.name}
          </a>
        </Dropdown>
      </DropdownWrapper>
    </Sidebar>
  );
};
