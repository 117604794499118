import styled from "styled-components";

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 8px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

export const DataSetsContainer = styled.div`
  margin-top: 20px;
`;

export const DataSetsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  margin-bottom: 8px;
`;

export const DataSetsHeaderMatchingText = styled.div`
  color: #868e96;
  font-size: 14px;
  margin-left: 10px;
`;
