import { Layout } from "antd";
import styled from "styled-components";

const { Content, Header } = Layout;

export const siderStyle = {
  position: "relative",
  background: "#fff",
};

export const contentStyle = {
  minHeight: "100vh",
};

export const CustomHeader = styled(Header)`
  top: 0px;
  left: 0;
  right: 0;
  z-index: 100;
  height: 3.125rem;
  max-height: 3.125rem;
  position: fixed;
  padding: 0;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  border-bottom: 0.0625rem solid rgb(233, 236, 239);

  @media (min-width: 48em) {
    display: none;
    height: 0rem;
    min-height: 0rem;
  }
`;

export const HeaderLayout = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  height: 3.125rem;
  margin-left: 1rem;
`;

export const CustomContent = styled(Content)`
  flex: 1 1 0%;
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3.125rem;

  @media (min-width: 48em) {
    padding-left: 19.75rem;
    padding-top: 1rem;
  }
`;

export const Sidebar = styled.nav`
  top: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 100;
  height: auto;
  width: 100%;
  position: fixed;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: rgb(241, 245, 249);
  padding: 1.5rem;
  @media (min-width: 48em) {
    width: 18.75rem;
    min-width: 18.75rem;
  }

  @media (max-width: 48em) {
    display: ${(props) => (props.open ? "block" : "none")};
  }
`;

export const LogoLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const LogoText = styled.div`
  color: rgb(73, 80, 87);
  font-size: 1.25rem;
  line-height: 1.55;
  text-decoration: none;
  font-weight: 700;
  margin-left: 3px;
`;

export const SidebarTopLayout = styled.div`
  padding-bottom: 1rem;
  margin-bottom: calc(1.5rem);
  border-bottom: 0.0625rem solid rgb(233, 236, 239);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 0%;
  box-sizing: border-box;
  position: relative;

  @media (min-width: 48em) {
    .close-btn {
      display: none;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 0rem;
  width: 100%;
  height: 100%;
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  bottom: 20px;
`;

export const TopMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  color: black;
`;

export const TopMenuTitles = styled.div`
  position: relative;
`;

export const TopMenuName = styled.div`
  position: relative;
  color: black;
`;

export const TopMenuEmail = styled.div`
  color: #000000a6;
`;
