import { useState } from "react";
import { Button, Form, Input, Typography } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";

import ApiService from "../../../services/ApiService";
import SessionService from "../../../services/SessionService";

import { LogInContainer, StyledForm } from "./styles";

const { Title } = Typography;

export const Login = () => {
  const [isLoggingIn, setLoggingIn] = useState(false);

  const onLogin = async (values) => {
    setLoggingIn(true);

    try {
      const { data } = await ApiService.post("/auth/login", values);
      SessionService.setAccessToken(data.token);
      SessionService.setUserData(data.user);
      window.location.href = SessionService.getPreviousUrl() ?? "/";
    } catch (e) {
      //
    }

    setLoggingIn(false);
  };

  return (
    <LogInContainer>
      <StyledForm initialValues={{}} onFinish={onLogin} autoComplete="off">
        <Title level={2}>Log In</Title>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Email is required!",
            },
            {
              type: "email",
              message: "Email is not a valid email!",
            },
          ]}
        >
          <Input prefix={<MailOutlined />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Password is required!",
            },
          ]}
        >
          <Input
            placeholder="Password"
            prefix={<LockOutlined />}
            type="password"
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" loading={isLoggingIn}>
            Log In
          </Button>
        </Form.Item>
      </StyledForm>
    </LogInContainer>
  );
};
