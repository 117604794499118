import { Form } from "antd";
import styled from "styled-components";

export const StyledForm = styled(Form)`
  padding: 24px;
  max-width: 360px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 8px;

  h2.ant-typography {
    margin-bottom: 24px;
    text-align: center;
    color: white;
  }

  button[type="submit"] {
    margin-top: 16px;
    width: 100%;
  }
`;

export const LogInContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url("/landing/img/gibbons_site.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;
