import styled from "styled-components";

export const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
  padding: 10px 0;

  table {
    background: white;

    .ant-table-cell {
      vertical-align: bottom;
    }
  }
`;

export const CardInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 10px 0px;
  gap: 4px;
`;

export const CardInfoText = styled.div`
  color: #868e96;
  font-size: 14px;
`;

export const CardInfoBoldText = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

export const CardInfoValueText = styled.div`
  font-size: 18px;
`;

export const BackToButton = styled.div`
  color: #087f5b;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  width: fit-content;
  padding: 10px;
  border-radius: 4px;

  &:hover {
    background: #e6fcf5;
  }
`;
