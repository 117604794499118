import { useState } from "react";
import { Button, Col, Row, Typography } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";

import { ChartCard } from "../../components/ChartCard";
import { Infomation, StatusInfo, WholeSaleCharts } from "./data";
import { InfoModal } from "../../components/InfoModal";
import { StatusCard } from "../../components/StatusCard";
import { TopSection } from "../../components/StatusCard/styles";

import { DateText, HeaderSection, TitleWrapper } from "./styles";

const { Title } = Typography;

dayjs.extend(advancedFormat);
dayjs.extend(timezone);

export const Home = () => {
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [currentInfo, setCurrentInfo] = useState();

  const handleInfoModalOpen = (type) => {
    setInfoModalOpen(true);
    setCurrentInfo(type);
  };
  const handleInfoModalClose = () => {
    setInfoModalOpen(false);
  };

  return (
    <div>
      <HeaderSection>
        <Title level={3} style={{ margin: 0 }}>
          Live Status
        </Title>
        <DateText>
          Current Time: {dayjs().format("MMM DD, YYYY hh:mm A z")}
        </DateText>
      </HeaderSection>
      <Row
        gutter={[4, 4]}
        justify={{ xs: "center", md: "start" }}
        style={{ width: "100%" }}
      >
        {StatusInfo.map((status, index) => (
          <Col key={index} sm={24} lg={12} xl={8} style={{ width: "100%" }}>
            <StatusCard status={status} />
          </Col>
        ))}
      </Row>
      <TopSection style={{ margin: "1.25rem 0" }}>
        <TitleWrapper>
          <Title level={3} style={{ margin: 0 }}>
            Wholesale Power Prices
          </Title>
          <Button
            icon={<QuestionCircleOutlined style={{ fontSize: 25 }} />}
            onClick={() => handleInfoModalOpen("wholeSale")}
            type="text"
          />
        </TitleWrapper>
        <DateText>
          Current Time: {dayjs().format("MMM DD, YYYY hh:mm A z")}
        </DateText>
      </TopSection>
      <Row
        gutter={[4, 4]}
        justify={{ xs: "center", md: "start" }}
        style={{ width: "100%" }}
      >
        {WholeSaleCharts.map((chartInfo, index) => (
          <Col key={index} sm={24} lg={12} xl={8} style={{ width: "100%" }}>
            <ChartCard chartInfo={chartInfo} />
          </Col>
        ))}
      </Row>
      <InfoModal
        info={Infomation[currentInfo] || { title: "", content: "" }}
        isOpen={infoModalOpen}
        onCancel={handleInfoModalClose}
      />
    </div>
  );
};
