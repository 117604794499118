import { notification } from "antd";
import axios from "axios";

import SessionService from "./SessionService";

const $http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

$http.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    if (SessionService.getAccessToken()) {
      newConfig.headers.Authorization = `Bearer ${SessionService.getAccessToken()}`;
    }
    return newConfig;
  },
  (err) => Promise.reject(err)
);

$http.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401 && SessionService.isLoggedIn()) {
      SessionService.logout();
    }
    if (err.response.status >= 400 && err.response.status < 500) {
      let messages = err.response.data.message || err.response.data.messages;
      if (typeof messages === "string") {
        notification.error({
          message: "Error!",
          description: messages,
        });
      } else {
        notification.error({
          message: "Error!",
          description: messages.map((message) => `- ${message}`).join(),
        });
      }
    }
    return Promise.reject(err);
  }
);

export default $http;
