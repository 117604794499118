import cookie from "js-cookie";
import { jwtDecode } from "jwt-decode";
import moment from "moment";

class SessionService {
  getOptions() {
    return { expires: moment().add(2, "hours").toDate() };
  }

  getAccessToken() {
    return cookie.get("access_token");
  }

  isLoggedIn() {
    return !!this.getAccessToken();
  }

  getExpiresIn() {
    try {
      const payload = jwtDecode(this.getAccessToken());
      return payload.exp * 1000 - Date.now();
    } catch (e) {
      return 0;
    }
  }

  setAccessToken(accessToken) {
    cookie.set("access_token", accessToken, this.getOptions());
  }

  getUserData() {
    const userData = cookie.get("user_data");
    if (!userData) return null;
    return JSON.parse(userData);
  }

  getUserRole() {
    const userData = this.getUserData();
    return userData?.role;
  }

  setUserData(userData) {
    cookie.set("user_data", JSON.stringify(userData), this.getOptions());
  }

  getPreviousUrl() {
    return sessionStorage.getItem("previous_url") ?? null;
  }

  logout() {
    sessionStorage.setItem("previous_url", window.location.href);
    cookie.remove("access_token");
    cookie.remove("user_data");
    window.location.reload();
  }
}

export default new SessionService();
