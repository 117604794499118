import { Card } from "antd";

import {
  CardLayout,
  CardTitle,
  InfoItem,
  InfoSection,
  InfoTitle,
  InfoValue,
  TimeWrapper,
  TopSection,
} from "./styles";

export const StatusCard = ({ status }) => {
  return (
    <Card>
      <CardLayout>
        <TopSection>
          <CardTitle>{status.title}</CardTitle>
          <TimeWrapper>{status.time}</TimeWrapper>
        </TopSection>
        <InfoSection>
          <InfoItem>
            <InfoTitle>Load</InfoTitle>
            <InfoValue>
              {status.load} <span>MW</span>
            </InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoTitle>Price</InfoTitle>
            <InfoValue>
              {status.price}
              <span>/MWh</span>
            </InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoTitle>Main Source</InfoTitle>
            <InfoValue>{status.mainSource}</InfoValue>
          </InfoItem>
        </InfoSection>
      </CardLayout>
    </Card>
  );
};
