import { useEffect, useState } from "react";
import { Button, Input, Popconfirm, Table, Tooltip } from "antd";
import {
  DeleteOutlined,
  DownCircleOutlined,
  FormOutlined,
} from "@ant-design/icons";

import ApiService from "../../services/ApiService";
import { AddUserModal } from "./AddUserModal";

import { StyledCard } from "../../components/styles";
import {
  SubscribersActionWrapper,
  TableWrapper,
  TopActions,
  TopWrapper,
  UsersWrapper,
} from "./styles";

const { Search } = Input;

export const Users = () => {
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUsers();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const getUsers = async () => {
    setLoading(true);

    const { data } = await ApiService.get("/users");

    try {
      setTableData(
        data.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        })
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const handleEdit = (record) => {
    setSelectedUser(record);
    setAddModalOpen(true);
  };

  const handleRemove = async (record) => {
    setLoading(true);
    await ApiService.delete(`/users/${record.id}`);

    getUsers();
    setLoading(false);
  };

  const SERVICES_COLUMNS = [
    {
      id: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      id: "role",
      title: "Role",
      dataIndex: "role",
    },
    {
      id: "email",
      title: "Email",
      dataIndex: "email",
    },
    {
      id: "action",
      key: "action",
      render: (_, record) => (
        <SubscribersActionWrapper>
          <Tooltip placement="bottom" title="Edit user">
            <Button
              icon={<FormOutlined />}
              onClick={() => handleEdit(record)}
            ></Button>
          </Tooltip>
          <Popconfirm
            cancelText="No"
            description="Are you sure to remove this?"
            onConfirm={() => handleRemove(record)}
            onCancel={() => {}}
            okText="Yes"
            title="Remove user"
          >
            <Tooltip placement="bottom" title="Remove user">
              <Button icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </SubscribersActionWrapper>
      ),
    },
  ];

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const handleSearch = () => {
    console.log(search);
  };
  const handleOpenAddModal = () => {
    setAddModalOpen(true);
  };

  const handleAddUser = async (data) => {
    setLoading(true);

    try {
      const payload = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        password: data.password,
        role: data.role,
      };
      if (selectedUser) {
        await ApiService.put(`/users/${selectedUser.id}`, payload);
        setSelectedUser(null);
      } else {
        await ApiService.post("/users", payload);
      }
      getUsers();
    } catch (e) {
      getUsers();
    }
    setLoading(false);
  };

  const handleCloseAddModal = () => {
    setAddModalOpen(false);
  };

  return (
    <UsersWrapper>
      <StyledCard
        title="Users"
        extra={
          <TopActions>
            <Button type="primary" icon={<DownCircleOutlined />}>
              Export
            </Button>
            <Button type="primary" onClick={() => handleOpenAddModal()}>
              Add user
            </Button>
          </TopActions>
        }
      >
        <TopWrapper>
          <Search
            allowClear
            enterButton="Search"
            style={{
              width: 300,
            }}
            value={search}
            onChange={handleSearchChange}
            onSearch={handleSearch}
          />
        </TopWrapper>
        <TableWrapper>
          <Table
            bordered
            dataSource={tableData}
            columns={SERVICES_COLUMNS}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              defaultPageSize: 50,
              total: tableData.length,
              size: "small",
              position: ["topLeft", "bottomRight"],
            }}
            loading={loading}
          />
        </TableWrapper>
      </StyledCard>
      <AddUserModal
        initialData={selectedUser}
        isOpen={addModalOpen}
        onSave={handleAddUser}
        onClose={handleCloseAddModal}
      />
    </UsersWrapper>
  );
};
